import { EventEmitter, Injectable, OnDestroy, Output } from "@angular/core";
import { ApiUrl } from "@core/consts/api-url";
import { ApiService } from "@core/http/api.service";
import { Market } from "@core/models/market/market";
import { TradeData } from "@core/models/trade/trade-data";
import { TradeFilter } from "@core/models/trade/trade-filter";
import { TradeOffer } from "@core/models/trade/trade-offer";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { NotificationService } from "./notificationService";
import { WalletCategory } from "./wallet/models/wallet-category";
import { WebSocketService } from "./web-socket/web-socket";

@Injectable({ providedIn: "root" })
export class TradeService implements OnDestroy {
  private _currencyPair: string;
  private _price: number;
  private _orderSelectedRow = new Subject<TradeOffer>();
  private _marketSymbol: string;
  private _symbolObservable = new Subject<any>();
  private _markObservable = new Subject<any>();
  @Output() orderRefresh = new EventEmitter<void>();
  market = new Subject<Market>();
  public totalPrecision: number = 8;
  public pricePrecision: number = 4;
  public amountPrecision: number = 4;
  public leverage: number = 0;
  public isFuture: boolean;

  constructor(
    private apiService: ApiService,
    private webSocketService: WebSocketService,
    private notificationService: NotificationService
  ) {
    this.notificationService.getSubscribe().subscribe((notification: any) => {
      if (notification.Type.toLowerCase() === "tradesucceed") {
        setTimeout(() => {
          this.setOrderRefresh();
        }, 1000);
      }
    });

    const wsObservable = this.webSocketService.initWebSocket(
      "trade-service",
      "general",
      (message: any) => {
        const response = JSON.parse(message);
        return response.Type == "price";
      },
      1000
    );
    wsObservable.subscribe((messages: any) => {
      messages.forEach((message: any) => {
        const data = JSON.parse(message).Data;
        if (data.symbol != this._marketSymbol) {
          return;
        }

        this._symbolObservable.next({
          price: data.price,
          priceChangePercent: Number(data.priceChangePercent),
          highPrice: Number(data.highPrice),
          lowPrice: Number(data.lowPrice),
          volume: Number(data.volume),
        });
      });
    });

    const wsObservable2 = this.webSocketService.initWebSocket(
      "trade-service-2",
      "general",
      (message: any) => {
        const response = JSON.parse(message);
        return response.Type == "markPrice";
      },
      1000
    );
    wsObservable2.subscribe((messages: any) => {
      messages.forEach((message: any) => {
        const data = JSON.parse(message).Data;
        if (data.symbol != this._marketSymbol) {
          return;
        }
        this._markObservable.next({
          markPrice: Number(data.markPrice),
        });
      });
    });
  }

  onInit() {
    this.getMarket(this.from, this.to).subscribe((r) => {
      this._symbolObservable.next({
        price: r.lastPrice,
        priceChangePercent: r.change24H,
        highPrice: r.high24H,
        lowPrice: r.low24H,
        volume: r.volume24H,
      });
    });
  }

  ngOnDestroy(): void {
    this.webSocketService.destoryWebSocket("trade-service");
  }

  set currencyPair(value) {
    this._currencyPair = value;
  }

  get currencyPair() {
    return this._currencyPair;
  }

  get from() {
    return this._currencyPair?.split("_")[0];
  }

  get to() {
    return this._currencyPair?.split("_")[1];
  }

  get currencyPairFrom() {
    if (!this._currencyPair) return "";
    return this._currencyPair.split("_")[0];
  }

  get currencyPairTo() {
    if (!this._currencyPair) return "";
    return this._currencyPair.split("_")[1];
  }

  set marketSymbol(value: string) {
    this._marketSymbol = value;
  }

  get marketSymbol() {
    return this._marketSymbol;
  }

  set price(value: number) {
    this._price = value;
  }

  get price() {
    return this._price;
  }

  getSymbolObservable() {
    return this._symbolObservable.asObservable();
  }

  getMarkObservable() {
    return this._markObservable.asObservable();
  }

  getHistoryList(param: TradeFilter): Observable<TradeData[]> {
    return this.apiService
      .get<any>(
        `${ApiUrl.trade}?currencyPair=${param.marketSymbol}&start=${param.startTime}&end=${param.endTime}&period=${param.granularity}`
      )
      .pipe(
        map((response) => {
          return response?.result?.map((x: any) => ({
            high: x.high,
            low: x.low,
            open: x.open,
            close: x.close,
            time: x.date,
            volume: x.volume,
          }));
        })
      );
  }

  getWalletBalanceFrom(walletCategory: WalletCategory) {
    return this.apiService
      .get<any>(
        `${ApiUrl.wallet}/${walletCategory}/${this.currencyPairFrom}/Balance`
      )
      .pipe(
        map((response) => {
          return response?.Result?.availableBalance;
        })
      );
  }

  getWalletBalanceTo(walletCategory: WalletCategory) {
    return this.apiService
      .get<any>(
        `${ApiUrl.wallet}/${walletCategory}/${this.currencyPairTo}/Balance`
      )
      .pipe(
        map((response) => {
          return response?.Result?.availableBalance;
        })
      );
  }

  getWalletBalance(walletCategory: WalletCategory, symbol) {
    return this.apiService
      .get<any>(`${ApiUrl.wallet}/${walletCategory}/${symbol}/Balance`)
      .pipe(
        map((response) => {
          return response?.Result?.availableBalance;
        })
      );
  }

  orderLimit(price: number, amount: number, marketName: string, side: number) {
    return this.apiService.post<any>(ApiUrl.placeLimitOrder, {
      price,
      amount,
      marketName,
      side,
    });
  }

  orderStopLimit(
    stop: number,
    limit: number,
    amount: number,
    marketName: string,
    side: number
  ) {
    return this.apiService.post<any>(ApiUrl.placeStopLimitOrder, {
      stop,
      limit,
      amount,
      marketName,
      side,
    });
  }

  orderMarketbyAmount(
    price: number,
    amount: number,
    marketName: string,
    side: number
  ) {
    return this.apiService.post<any>(ApiUrl.placeMarketOrder, {
      price,
      amount,
      marketName,
      side,
    });
  }

  orderMarketByTotal(
    price: number,
    total: number,
    marketName: string,
    side: number
  ) {
    return this.apiService.post<any>(ApiUrl.placeMarketOrder, {
      price,
      total,
      marketName,
      side,
    });
  }

  getOpenOrders(fromSymbol: string, toSymbol: string, size = 30) {
    return this.apiService
      .get<any>(
        `${ApiUrl.marketOrder}/${fromSymbol}/${toSymbol}/OpenOrders?Size=${size}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getTradeHistories(fromSymbol: string, toSymbol: string, size = 30) {
    return this.apiService
      .get<any>(
        `${ApiUrl.marketOrder}/${fromSymbol}/${toSymbol}/TradeHistories?Size=${size}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getOrderHistories(fromSymbol: string, toSymbol: string, size = 30) {
    return this.apiService
      .get<any>(
        `${ApiUrl.marketOrder}/${fromSymbol}/${toSymbol}/OrderHistories?Size=${size}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getMarket(fromSymbol: string, toSymbol: string) {
    return this.apiService
      .get<any>(`${ApiUrl.markets}/${fromSymbol}/${toSymbol}`)
      .pipe(
        map((response) => {
          this.market.next(response?.Result);
          this.totalPrecision = response?.Result.totalPrecision;
          this.pricePrecision = response?.Result.pricePrecision;
          this.amountPrecision = response?.Result.amountPrecision;
          return response?.Result;
        })
      );
  }

  marketObservable() {
    return this.market.asObservable();
  }

  getSellOrderBook(fromSymbol: string, toSymbol: string) {
    return this.apiService
      .get<any>(`${ApiUrl.orderBook}/${fromSymbol}/${toSymbol}/SellOrderBook`)
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getBuyOrderBook(fromSymbol: string, toSymbol: string) {
    return this.apiService
      .get<any>(`${ApiUrl.orderBook}/${fromSymbol}/${toSymbol}/BuyOrderBook`)
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getTrades(fromSymbol: string, toSymbol: string) {
    return this.apiService
      .get<any>(`${ApiUrl.markets}/${fromSymbol}/${toSymbol}/Trades`)
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getOrderSelectedRow() {
    return this._orderSelectedRow.asObservable();
  }

  setOrderSelectedRow(value: TradeOffer) {
    this._orderSelectedRow.next(value);
  }

  setOrderRefresh() {
    this.orderRefresh.emit();
  }

  cancelOrder(orderId: string) {
    return this.apiService
      .patch<any>(`${ApiUrl.placeLimitOrder}/${orderId}/Cancel`, {})
      .pipe(
        map((response) => {
          return null;
        })
      );
  }

  getLeverageRange() {
    return this.apiService
      .get<any>(`${ApiUrl.futureContracts}/${this._marketSymbol}/leverages`)
      .pipe(
        map((r) => {
          return r.Result;
        })
      );
  }

  getLeverage() {
    return this.apiService
      .get<any>(
        `${ApiUrl.futureContracts}/${this._marketSymbol}/customerLeverage`
      )
      .pipe(
        map((r) => {
          return r.Result;
        })
      );
  }

  setLeverage(notional: number) {
    return this.apiService
      .post<any>(ApiUrl.leverage, {
        symbol: this.currencyPairFrom + this.currencyPairTo,
        notional,
      })
      .pipe(
        map((r) => {
          this.leverage = notional;
        })
      );
  }

  getFutureTrade() {
    return this.apiService
      .get<any>(`${ApiUrl.futureContracts}/${this._marketSymbol}/trades`)
      .pipe(
        map((r) => {
          return r.Result;
        })
      );
  }

  futureOrderLimit(
    symbol: string,
    side: number,
    type: number,
    size: number,
    price: number,
    reduceOnly: boolean
  ) {
    return this.apiService.post<any>(ApiUrl.futureOrder, {
      symbol,
      side,
      type,
      quantity: size,
      price,
      reduceOnly,
      timeInForce: 1,
    });
  }

  getFutureSellOrderBook() {
    return this.apiService
      .get<any>(
        `${ApiUrl.futureOrderBook}/2?symbol=${this.currencyPairFrom}${this.currencyPairTo}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getFutureBuyOrderBook() {
    return this.apiService
      .get<any>(
        `${ApiUrl.futureOrderBook}/1?symbol=${this.currencyPairFrom}${this.currencyPairTo}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getFutureTrades() {
    return this.apiService
      .get<any>(
        `${ApiUrl.futureContracts}/${this.currencyPairFrom}${this.currencyPairTo}/Trades`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getFutureOpenOrders(size = 30) {
    return this.apiService
      .get<any>(
        `${ApiUrl.futureOrder}/${this.currencyPairFrom}${this.currencyPairTo}/OpenOrders?Size=${size}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getFutureOrderHistories(size = 30) {
    return this.apiService
      .get<any>(
        `${ApiUrl.futureOrder}/${this.currencyPairFrom}${this.currencyPairTo}/OrderHistories?Size=${size}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getFutureTradeHistories(size = 30) {
    return this.apiService
      .get<any>(
        `${ApiUrl.futureOrder}/${this.currencyPairFrom}${this.currencyPairTo}/TradeHistories?Size=${size}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getFutureTransactionHistories(size = 30) {
    return this.apiService
      .get<any>(`${ApiUrl.wallet}/Transactions?Size=${size}&category=2`)
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  getFuturePositions(size = 30) {
    return this.apiService
      .get<any>(
        `${ApiUrl.position}/${this.currencyPairFrom}${this.currencyPairTo}?Size=${size}`
      )
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }
  transmissions(amount, srcWalletCategory, dstWalletCategory) {
    return this.apiService
      .post<any>(`${ApiUrl.transmissions}`, {
        amount: amount,
        srcWalletCategory: srcWalletCategory,
        dstWalletCategory: dstWalletCategory,
      })
      .pipe(
        map((response) => {
          return response?.Result;
        })
      );
  }

  cancelFutureOrder(orderId: string) {
    return this.apiService
      .post<any>(`${ApiUrl.futureOrder}/${orderId}/Cancel`, {})
      .pipe(
        map((response) => {
          return null;
        })
      );
  }

  cancelFuturePosition(symbol: string, price: number, quantity: number) {
    return this.apiService
      .post<any>(`${ApiUrl.position}/${symbol}/Close`, {
        symbol,
        type: 1,
        price,
        quantity,
      })
      .pipe(
        map((response) => {
          return null;
        })
      );
  }

  futureTplOrderLimit(
    symbol: string,
    side: number,
    type: number,
    size: number,
    price: number,
    reduceOnly: boolean,
    takeProfitOrderStopPrice: number,
    takeProfitOrderWorkingType: number,
    stopLostOrderStopPrice: number,
    stopLostOrderWorkingType: number
  ) {
    return this.apiService.post<any>(`${ApiUrl.futureOrder}/strategy`, {
      symbol,
      side,
      type,
      quantity: size,
      price,
      reduceOnly,
      timeInForce: 1,
      takeProfitOrder: {
        symbol,
        side,
        type,
        quantity: size,
        price,
        reduceOnly,
        timeInForce: 1,
        stopPrice: takeProfitOrderStopPrice,
        workingType: takeProfitOrderWorkingType,
        placeType: 0,
      },
      stopLostOrder: {
        symbol,
        side,
        type,
        quantity: size,
        price,
        reduceOnly,
        timeInForce: 1,
        stopPrice: stopLostOrderStopPrice,
        workingType: stopLostOrderWorkingType,
        placeType: 0,
      },
    });
  }
}
